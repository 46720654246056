<template>
  <base-layout-two
    page-title="Add Line Item"
    :page-default-back-link="`/users/${this.$route.params.id}/folios/${this.$route.params.folio_id}/`"
  >
    <v-form @submit="onSubmit">
      <base-card title="New Line Item">
        <ion-grid>
          <ion-row>
            <!-- Description -->
            <ion-col>
              <base-input label-text="Description *">
                <v-field
                  name="description"
                  v-slot="{ field }"
                  v-model="transaction.description"
                  :rules="required"
                >
                  <ion-input
                    name="description"
                    v-bind="field"
                    placeholder="Head Massage"
                    type="text"
                    autocapitalize
                    autocorrect
                  ></ion-input>
                </v-field>
              </base-input>
              <v-error-message
                name="description"
                class="error-message"
              ></v-error-message>
            </ion-col>
          </ion-row>

          <ion-row>
            <!-- Amount -->
            <ion-col>
              <base-input
                label-text="Amount (Positive numbers are Credits, Negative numbers are Charges) *"
              >
                <v-field
                  name="amount"
                  v-slot="{ field }"
                  v-model="transaction.amount"
                  :rules="required"
                >
                  <ion-input
                    name="amount"
                    v-bind="field"
                    placeholder="0.00"
                    type="number"
                  ></ion-input>
                </v-field>
              </base-input>
              <v-error-message
                name="amount"
                class="error-message"
              ></v-error-message>
            </ion-col>
          </ion-row>

          <ion-row>
            <!-- Amount -->
            <ion-col>
              <base-input label-text="Date *">
                <v-field
                  name="date"
                  v-slot="{ field }"
                  v-model="transaction.date"
                  :rules="required"
                >
                  <ion-input
                    name="date"
                    v-bind="field"
                    placeholder=""
                    type="date"
                  ></ion-input>
                </v-field>
              </base-input>
              <v-error-message
                name="amount"
                class="error-message"
              ></v-error-message>
            </ion-col>
          </ion-row>

          <ion-row>
            <!-- Memo -->
            <ion-col>
              <base-input label-text="Memo">
                <v-field
                  name="memo"
                  v-slot="{ field }"
                  v-model="transaction.memo"
                >
                  <ion-textarea
                    name="memo"
                    :auto-grow="true"
                    rows="1"
                    v-bind="field"
                    placeholder="Internal note about this charge"
                    autocapitalize
                    autocorrect
                  ></ion-textarea>
                </v-field>
              </base-input>
              <v-error-message
                name="amount"
                class="error-message"
              ></v-error-message>
            </ion-col>
          </ion-row>
        </ion-grid>
      </base-card>

      <ion-button type="submit" expand="block" class="margin-x-8 margin-y-16"
        >Save</ion-button
      >
    </v-form>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonButton,
  IonTextarea,
} from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapGetters, mapActions } from "vuex";
import { format } from "date-fns";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    IonTextarea,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  computed: {
    ...mapGetters(["authUser"]),
  },

  data() {
    return {
      transaction: {
        type: "line_item",
        is_deposit: 0,
        folio_id: this.$route.params.folio_id,
        date: format(new Date(), "yyyy-MM-dd"),
      },
      required: yup.string().required(),
    };
  },

  async ionViewWillEnter() {
    this.fetchReservation();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchReservation() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/reservations/${this.$route.params.id}`)
        .then(async (response) => {
          this.reservation = response.data.reservation;

          this.transaction.user_id = this.reservation.user_id;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async onSubmit() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/transactions`,
        data: this.transaction,
      };

      config.data.created_by_id = this.authUser.id;

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.transaction = response.data.transaction;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Line Item Created", color: "secondary" });

          this.$router.replace(
            `/users/${this.$route.params.id}/folios/${this.$route.params.folio_id}`
          );
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>